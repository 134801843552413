<template>
  <div>
    <div class="box" v-show="opsets">
      <img
        class="cha"
        @click="close"
        src="../assets/img/ding/icon-close.png"
        alt=""
      />
      <div class="box-main">
        <div class="main-nav">
          <h1>外协价格设置</h1>
        </div>
        <div class="rescue">
          <label class="label1" for="">救援项目</label>
          <el-select v-model="rescueType" placeholder="救援项目">
            <el-option
              v-for="item in rescueTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="rescue1" v-show="rescueType == 1 || rescueType == 2 || rescueType == 19">
          <label class="label1" for="">车辆类型</label>
          <el-select v-model="carType" placeholder="车辆类型">
            <el-option
              v-for="item1 in cartypelist"
              :key="item1.id"
              :label="item1.type_name"
              :value="item1.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="rescue1">
          <label class="label" for="">价格设置</label>
          <el-radio
            v-model="chargeType"
            label="1"
            size="large"
            >一口价</el-radio
          >
          <el-radio
            v-model="chargeType"
            label="2"
            size="large"

            v-if="rescueType != 13"
            >公里价</el-radio
          >
          <el-radio
            v-model="chargeType"
            label="2"
            size="large"

            v-if="rescueType == 13"
            >个数价</el-radio
          >
        </div>
        <div class="rescue1" v-if="chargeType >= 1">
          <div class="priceelect">
            <div class="priceelect1">
                <input class="priceelect-span" v-model="guidePrice" placeholder="基准价格">
                <span
                  class="priceelect-span"
                  >元</span>
              </div>
              <div v-if="rescueType != 13"> <div class="priceelect1" v-if="chargeType == 2">
                <input class="priceelect-span" v-model="guideKm" placeholder="基准公里">
                <span
                  class="priceelect-span"
                  >公里</span>
              </div>
              <div class="priceelect1"  v-if="chargeType == 2">
                <input class="priceelect-span" v-model="kmPrice" placeholder="公里价">
                <span
                  class="priceelect-span"
                  >元/公里</span>
              </div></div>
              <div v-if="rescueType == 13"> <div class="priceelect1" v-if="chargeType == 2">
                <input class="priceelect-span" v-model="guideKm" placeholder="基准个数">
                <span
                  class="priceelect-span"
                  >个</span>
              </div>
              <div class="priceelect1"  v-if="chargeType == 2">
                <input class="priceelect-span" v-model="kmPrice" placeholder="超基准价">
                <span
                  class="priceelect-span"
                  >元/个</span>
              </div></div>
             
          </div>
        </div>
        <div class="rescue1">
          <label class="label" for="">取消有费</label>
          <el-radio
            v-model="cancelType"
            label="1"
            size="large"
            >公里价</el-radio
          >
          <el-radio
            v-model="cancelType"
            label="2"
            size="large"

            >一口价</el-radio
          >
          <el-radio
            v-model="cancelType"
            label="3"
            size="large"

            >百分比</el-radio
          >
        </div>
        <div class="rescue1"  v-if="cancelType >= 1">
          <div class="priceelect">
            <div class="priceelect2">
                <input class="priceelect-span" v-model="cancelTxt" placeholder="具体数字">
                <span
                  class="priceelect-span1"
                  v-if="cancelType == 1"
                  >元/公里</span>
                <span
                  class="priceelect-span1"
                  v-if="cancelType == 2"
                  >元</span>
                <span
                  class="priceelect-span1"
                  v-if="cancelType == 3"
                  >%</span>
              </div>         
          </div>
        </div>
        <div class="rescue1">
          <label class="label" for="">救援失败</label>
          <el-radio
            v-model="failType"
            label="1"
            size="large"

            >公里价</el-radio
          >
          <el-radio
            v-model="failType"
            label="2"
            size="large"

            >一口价</el-radio
          >
          <el-radio
            v-model="failType"
            label="3"
            size="large"
            >百分比</el-radio
          >
        </div>
        <div class="rescue1" v-if="failType >= 1">
          <div class="priceelect">
            <div class="priceelect2">
                <input class="priceelect-span" v-model="failTxt" placeholder="具体数字">
                <span
                  class="priceelect-span1"
                  v-if="failType == 1"
                  >元/公里</span>
                <span
                  class="priceelect-span1"
                  v-if="failType == 2"
                  >元</span>
                <span
                  class="priceelect-span1"
                  v-if="failType == 3"
                  >%</span>
              </div>         
          </div>
        </div>
        <!-- <div class="rescue1">
          <label class="label1" for="">流程</label>
          <span>{{liucheng}}</span>
        </div> -->

        <div class="btn">
          <button class="set" @click="save">保存</button>
          <button class="cancel" @click="close">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import * as api from "../api/index";



export default {
  props:["isopset",'op_a','op_b'],
  data() {
    return {
      rescueType:'',
      carType:'',
      chargeType:'',
      guidePrice:'',
      guideKm:'',
      kmPrice:'',
      cancelType:'',
      cancelTxt:'',
      failType:'',
      failTxt:'',
      opsets: this.isopset,
      rescueTypeList:[],
      cartypelist:[]
    };
  },
  created()
  {
    this.getlist();  
  },
  methods: {
    close() {
      this.failTxt = '';
      this.failType = '';
      this.cancelTxt = '';
      this.cancelType = '';
      this.guidePrice = '';
      this.guideKm = '';
      this.kmPrice = '';
      this.chargeType = '';
      this.rescueType = '';
      this.carType = '';
      this.$emit('opclose',1);
    },
    clearall()
    {
      this.failTxt = '';
      this.failType = '';
      this.cancelTxt = '';
      this.cancelType = '';
      this.guidePrice = '';
      this.guideKm = '';
      this.kmPrice = '';
      this.chargeType = '';
      this.rescueType = '';
      this.carType = '';
    },
    async getlist()
    {
      let res = await api.oplist();
      //console.log(res);
      this.rescueTypeList = res.data;
      this.cartypelist = res.data2;
    },
    async getdetail(a,b)
    {
        let res = await api.oppricedetail(a,b);
        if(res.code == 1)
        {
          this.failTxt = res.data.fail_txt;
          this.failType = res.data.fail_type;
          this.cancelTxt = res.data.cancel_txt;
          this.cancelType = res.data.cancel_type;
          this.guidePrice = res.data.guide_price;
          this.guideKm = res.data.charge_type == 1?'':res.data.guide_km;
          this.kmPrice = res.data.charge_type == 1?'':res.data.km_price;
          this.chargeType =  res.data.charge_type;
        }else{
          this.failTxt = '';
          this.failType = '';
          this.cancelTxt = '';
          this.cancelType = '';
          this.guidePrice = '';
          this.guideKm = '';
          this.kmPrice = '';
          this.chargeType = '';
        }
    },  
    async save()
    {
        if(this.rescueType == '')
        {
           this.$message.warning('请选择服务类型');
           return false;         
        }

        if((this.rescueType == 1 || this.rescueType == 2 || this.rescueType == 19) && this.carType == '')
        {
           this.$message.warning('请选择对应车辆类型');
           return false;   
        }

        if(this.chargeType =='')
        {
          this.$message.warning('请选择价格类型');
          return false;
        }else if(this.chargeType == 1)
        {
          if(this.guidePrice == '')
          {
            this.$message.warning('请填写基准价格');
            return false;
          }
        }else if(this.chargeType == 2)
        {
          if(this.guidePrice == '')
          {
            this.$message.warning('请填写基准价格');
            return false;
          }
          if(this.guideKm == '')
          {
            this.$message.warning('请填写基准数');
            return false;
          }
          if(this.kmPrice == '')
          {
            this.$message.warning('请填写超基准单价');
            return false;
          }
        }

        if(this.cancelType == '')
        {
            this.$message.warning('请选择取消有费规则类型');
            return false;
        }else if(this.cancelType == 1){
          if(this.cancelTxt == '')
          {
            this.$message.warning('请填写取消有费公里价');
            return false;
          }

        }else if(this.cancelType == 2){
          if(this.cancelTxt == '')
          {
            this.$message.warning('请填写取消有费一口价');
            return false;
          }
        }else if(this.cancelType == 3){
          if(this.cancelTxt == '')
          {
            this.$message.warning('请填写取消有费百分数');
            return false;
          }else if(this.cancelTxt > 100)
          {
            this.$message.warning('百分数不能大于100');
            return false;
          }
        }

        if(this.failType == '')
        {
            this.$message.warning('请选择救援失败规则类型');
            return false;
        }else if(this.failType == 1){
          if(this.failTxt == '')
          {
            this.$message.warning('请填写救援失败公里价');
            return false;
          }

        }else if(this.failType == 2){
          if(this.failTxt == '')
          {
            this.$message.warning('请填写救援失败一口价');
            return false;
          }
        }else if(this.failType == 3){
          if(this.failTxt == '')
          {
            this.$message.warning('请填写救援失败百分数');
            return false;
          }else if(this.failTxt > 100)
          {
            this.$message.warning('百分数不能大于100');
            return false;
          }
        }

        let rescueType = this.rescueType;
        let carType = this.rescueType == 1 || this.rescueType == 2 || this.rescueType == 19?this.carType:null;
        let chargeType = this.chargeType;
        let guidePrice = this.guidePrice;
        let guideKm = this.chargeType == 2?this.guideKm:null;
        let kmPrice = this.chargeType == 2?this.kmPrice:null;
        let cancelType = this.cancelType;
        let cancelTxt = this.cancelTxt;
        let failType = this.failType;
        let failTxt = this.failTxt;

        let res = await api.saveopprice(rescueType,carType,chargeType,guidePrice,guideKm,kmPrice,cancelType,cancelTxt,failType,failTxt);
        if(res.code == 1)
        {
          this.$message.success('成功');
          this.clearall();
          this.$emit('opsx',1);
        }else{
          this.$message.warning(res.message);
        }

    }
 
  },
  watch:{
    isopset(newval)
    {
      this.opsets = newval;
    },
    op_a(newval)
    {
      if(newval != '')
      {
        this.rescueType = newval;
      }
    },
    op_b(newval){
      if(newval != '')
      {
        this.carType = newval;
      }
    },
    rescueType(newval)
    {
      if(newval != '')
      {
        if(newval == 1 || newval == 2 || newval == 19)
        {
          if(this.carType != '')
          {
            this.getdetail(newval,this.carType);
          }
        }else{
            this.getdetail(newval,'');
        }
      }

    },
    carType(newval)
    {
      if(newval != '')
      {
        if(this.rescueType == 1 || this.rescueType == 2 || this.rescueType == 19)
        {
          this.getdetail(this.rescueType,newval);
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  width: 640px;
  height: 920px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 22;
  background-color: #fff;
}
.cha {
  float: left;
  width: 103px;
  height: 103px;
  margin-top: 36px;
  margin-left: -29px;
  cursor: pointer;
}
.box-main {
  width: 600px;
  height: 920px;
  background: #fff;
  margin-left: 40px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  width: 180px;
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 80px;
  color: #2a3346;
  opacity: 1;
}
.rescue {
  margin-top: 63px;
  margin-left: 30px;
}
.rescue1 {
  margin-top: 30px;
  margin-left: 30px;
  height: 40px;
  //   background-color: orange;
}
.label1,
.label {
  width: 56px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.label1 {
  margin-right: 20px;
}
.label {
  margin-right: 34px;
}
.el-select {
  width: 260px;
}
.el-input {
  width: 260px;
}

.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 106px;
  margin-top: 44px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
  cursor: pointer;
}
span {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2c68ff;
  opacity: 1;
  margin-left: 10px;
  cursor: pointer;
}

.priceelect {
  width: 460px;
  height: 32px;
  margin-left: 90px;
  margin-top: 12px;
  position: relative;
  //background-color: skyblue;
}

.priceelect1,
.priceelect2,
.priceelect3,
.priceelect4 {
  float: left;
  margin-right: 1px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
}
.priceelect1,
.priceelect2 {
  // width: 80px;
  height: 32px;
}
.priceelect3 {
  // width: 100px;
  height: 32px;
}
.priceelect4 {
  // width: 110px;
  height: 32px;
}
.priceelect-span {
  width: 55px;
  height: 28px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  text-align: center;
  color: #2a3346;
  opacity: 1;
  border: none;
  padding: 0px;
}
.priceelect-span1 {
  width: 60px;
  height: 28px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  text-align: center;
  color: #2a3346;
  opacity: 1;
  border: none;
  padding: 0px;
}

</style>
