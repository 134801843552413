<template>
  <div class="tibox">
    <div class="nav-box">
        <div class="screen" @click="add">
          <img src="../assets/img/ding/tianjia.png" alt="" />
          <p>添加</p>
        </div>
      </div>
    <div class="timain">
      <div class="tibiao">
        <el-table :data="oplist" stripe height="90%" width="100%">

          <el-table-column label="服务项目" width="190">
            <template #default="scope">
              {{ scope.row.rescue_name }}
            </template>
          </el-table-column>
          <el-table-column label="服务车型" width="250">
            <template #default="scope">
              {{ scope.row.type_name }}
            </template>
          </el-table-column>
          <el-table-column label="价格类型" width="250">
            <template #default="scope">
              {{ scope.row.chargeType }}
            </template>
          </el-table-column>
          <el-table-column label="价格标准" width="250">
            <template #default="scope">
              {{ scope.row.pricelevel }}
            </template>
          </el-table-column>
          <el-table-column label="取消有费" width="250">
            <template #default="scope">
              {{ scope.row.cancellevel }}
            </template>
          </el-table-column>
          <el-table-column label="救援失败" width="250">
            <template #default="scope">
              {{ scope.row.faillevel }}
            </template>
          </el-table-column>

          <el-table-column label="操作" fixed="right" width="150">
            <template #default="scope">
             <el-button link type="primary" size="small" @click="editop(scope.row.rescueType,scope.row.carType)">修改</el-button>
             <el-button link type="primary" size="small" @click="delop(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
  <oprice :isopset="ops" :op_a="opa" :op_b="opb" @opclose="opclose" @opsx="opsx" ></oprice>
</template>

<script>
import { ref } from "vue";
import * as api2 from "../api/index.js";
import * as api from "../api/join.js";
import * as api1 from "../api/order";
import * as ola from "../api/olawebsock.js";
import Part from "../components/part.vue";
import deposit from "../components/deposit.vue";
import oprice from "../components/oprice.vue";
import { ElMessageBox } from "element-plus";
const value = ref("");
export default {
  components: { Part,deposit,oprice },
  data() {
    return {
      ops:false,
      oplist:[],
      opa:'',
      opb:'',
    };
  },
  async created() {
    this.getlist();
  },

  methods: {
    add()
    {
      this.opa = '';
      this.opb = '';
      this.ops = true;
    },
    opclose()
    {
      this.ops = false;
    },
    opsx(){
      this.ops = false;
      this.getlist();
    },
    editop(a,b)
    {
      this.opa = a;
      this.opb = b;
      this.ops = true;
    },
    async delop(id)
    {
      ElMessageBox.confirm("是否确定是否删除此条外协价格", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await api2.delopprice(id);
          if(res.code == 1)
          {
            this.getlist();
          }else{
            this.$message.warning('失败');
          }
        })
        .catch(() => {
          this.$message.info("取消");
        });
    
    },
    async getlist()
    {
      let res = await api2.getoplist();
      this.oplist = res.data;
    }
  },
  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
    getisFin() {
      return this.$store.state.isFin;
    },
  },
  watch: {
  },
};
</script>

<style>
.el-table thead.is-group th.el-table__cell {
  background-color: #f5f5f5;
  color: #666;
}
.monthtit {
  height: 40px !important;
  text-align: center !important;
}
</style>
<style lang="less" scoped>
.nav-box {
  width: 1670px;
  height: 120px;
    background-color: #fff;
  overflow: hidden;
}
.screen {
  float: right;
  width: 130px;
  height: 50px;
  margin-top: 30px;
  margin-right: 60px;
  background: #2c68ff;
  opacity: 1;
  cursor: pointer;
  border-radius: 2px;
}
img {
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  margin-left: 27px;
  margin-top: 14px;
  opacity: 1;
}
p {
  float: left;
  margin-top: 27px;
  width: 32px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 0px;
  color: #ffffff;
  opacity: 1;
}
.tibiao /deep/ .el-table .cell {
  white-space: pre-line;
}
.tibox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.timain {
  width: 1730px;
  height: 100%;
  margin-left: 190px;
  padding: 30px;
  box-sizing: border-box;
  background-color: #f8fafc;
}
.tinav {
  display: flex;
  align-items: center;
}
.tihead {
  width: 100%;
  height: 120px;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
.left {
  float: left;
  // width: 300px;
  height: 32px;
  background: #ffffff;
  // border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: relative;
  box-sizing: border-box;
  margin-left: 20px;
}
.jing {
  float: left;
  width: 32px;
  height: 32px;
  background: #f7f9fb;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  top: -1px;
  right: -1px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.icon {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  opacity: 1;
}
.tisou,
.tiru {
  width: 100%;
  height: 100%;
  border: 0;
  box-sizing: border-box;
  padding-left: 12px;
  outline: none;
}
.tiru {
  border: 1px solid #ccc;
}
.tishu {
  width: 250px;
  height: 32px;
  box-sizing: border-box;
  margin-left: 20px;
}
.btn {
  float: right;
  // width: 180px;
  height: 32px;
  opacity: 1;
  box-sizing: border-box;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border-radius: 2px;
  border: 0;
  cursor: pointer;
  box-sizing: border-box;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  margin-left: 20px;
  border: 1px solid #727e96;
  opacity: 1;
  border-radius: 2px;
  cursor: pointer;
  box-sizing: border-box;
}
.tibiao {
  width: 100%;
  //   height: 665px;
  height: 100%;
  background-color: #fff;
  padding: 15px;
  box-sizing: border-box;
  //   margin-top: 20px;
}
.tibiao /deep/ .el-table th.el-table__cell.is-leaf {
  background-color: #f5f5f5;
  height: 40px !important;
  color: #000;
}
.tibiao /deep/ .el-table thead.el-table__cell.is-leaf {
  background-color: #f5f5f5;
  height: 40px !important;
  color: #000;
}
.tibiao /deep/ .el-table__row {
  height: 70px !important;
  box-sizing: border-box;
}

.tibiao /deep/ .el-table__cell {
  padding: 0;
}
.jinXing-sorter {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 40px;
  bottom: 50px;
}
.call {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url("../assets/img/index/call.png");
  background-size: contain;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
  margin-left: 5px;
}
.carcall {
  display: flex;
  align-items: center;
}
.left /deep/ .el-select .el-input {
  width: 200px;
  margin-right: 20px;
}
.tibiao /deep/ .el-table-fixed-column--right:last-of-type {
  // width: 200px;
  // display: flex;
  // align-items: center;
  height: 70px !important;
}
.etcprice {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-right: 30px;
  color: #606266;
}

.seek {
  float: left;
  // width: 200px;
  // height: 40px;
  background: #ffffff;
  // border: 1px solid #dadfe6;
  opacity: 1;
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;
  display: flex;
}
.sekint {
  float: left;
  width: 200px;
  height: 37px;
  padding: 15px;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 32px;
  border: 1px solid #dadfe6;
}
.glass {
  // float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 30px;
  margin-top: -1px;
  background: #f7f9fb;
  // border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  top: 2px;
  right: 1px;
  cursor: pointer;
}
.icon {
  width: 14px;
  height: 14px;
  // margin-left: 13px;
  // margin-top: 13px;
  cursor: pointer;
}

.chaxun {
  width: 80px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2c68ff;
  color: #fff;
  border: 0;
  cursor: pointer;
}
.tinav /deep/ .el-range-editor.el-input__inner {
  width: 250px;
  margin: 0 !important;
}
.tinav /deep/ .el-form-item {
  margin: 0 !important;
  margin-right: 20px !important;
}
.xiangqing {
  font-style: normal;
  color: #2c68ff;
  cursor: pointer;
}
.tiaobtn {
  margin-right: 10px;
}
.tibiao /deep/ .el-table__footer-wrapper tbody td.el-table__cell {
  font-size: 15px;
  color: #000;
  font-weight: bold;
}
.pricetit{
  cursor: pointer;
}

.fuwuproject{
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  margin-top: 30px;
  margin-left: 30px;
  display: inline-block;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
}
</style>